@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Cera Pro';
  src: url('/public/fonts/cera-pro/CeraPro-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('/public/fonts/cera-pro/CeraPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
    overscroll-behavior: none;
    overflow: hidden;
    position: fixed; /* Prevent scrolling */
    width: 100%; /* Ensure full width */
    outline: "none";
    -webkit-tap-highlight-color: transparent;
    font-family: "Cera Pro";
  }

  